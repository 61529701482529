import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/home.css";
import brandLogoWhite from "../assets/img/logo_svg.svg";
import brandLogoBlue from "../assets/img/logo_blue_svg.svg";
// import brandFavicon from "../assets/fab icon.png";
// import favicon from "../assets/fav-icon.jpg";
import Home from "../modules/home/Home";
import Design from "../modules/services/Design";
import Technology_software from "../modules/services/Technology_software";
import Technology_hardware from "../modules/services/Technology_hardware";
import Business from "../modules/services/Business_marketing";
import NetworkSecurity from "../modules/services/Network_security";
import Ecommerce from "../modules/e-commerce/E-commerce";
import Technology from "../modules/technology/Technology";
import Contact from "../modules/contact/Contact";
import About from "../modules/about/About";
import Software_licencing from "../modules/services/Software_licencing";
import Blog from "../modules/blog/Blog";
import Blog_single from "../modules/blog-single/Blog_single";
import "../css/header.css";
import favicon from "../assets/projects and logos/bintech-fav-icon.png";
import NavIcon_Design from "../assets/navbar/design.svg";
import NavIcon_Software from "../assets/navbar/software.svg";
import NavIcon_Hardware from "../assets/navbar/hardware.svg";
import NavIcon_BusinessGrowth from "../assets/navbar/business.svg";
import NavIcon_Ecommerce from "../assets/navbar/ecommerce.svg";
import NavIcon_NetworkandSecurity from "../assets/navbar/network.svg";
// import NavIcon_Technology from "../assets/navbar/NavIcon_Technology.png";
import NavIcon_Travel_Tech from "../assets/navbar/travel.svg";
import NavIcon_licencing from "../assets/navbar/licencing.svg";
import Privacy_Policy from "../modules/policy/Privacy_Policy";
import Refund_Policy from "../modules/policy/Refund_Policy";
import Website_Usage_Policy from "../modules/policy/Website_Usage_Policy";

const Header = () => {
  const [contactHeader, setContactHeader] = useState(false);
  const [homeHeader, setHomeHeader] = useState(true);
  const [overlay, setOverlay] = useState(true);
  const param = useParams();
  const url = window.location.href;
  const lastSegment = url.split("/").pop();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    console.log("dshgkjhdsg");
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (lastSegment === "contact") {
      setContactHeader(true);
      setHomeHeader(false);
    } else if (lastSegment === "" || lastSegment === "#") {
      setHomeHeader(true);
    } else {
      setContactHeader(false);
      setHomeHeader(false);
    }

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [lastSegment]);

  const isSticky = (e) => {
    const header = document.querySelector("#navbarMain");
    const scrollTop = window.scrollY;
    scrollTop >= 10 ? setheader(header) : onTop(header);
  };

  const setheader = (header) => {
    header.classList.add("is-sticky");
    setHomeHeader(false);
  };

  const onTop = (header) => {
    const url = window.location.href;
    const segment = url.split("/").pop();
    header.classList.remove("is-sticky");
    if (segment === "" || segment === "#") {
      setHomeHeader(true);
    } else {
      setContactHeader(false);
      setHomeHeader(false);
    }
    // if (segment === "contact") {
    //   setHomeHeader(true);
    // } else if (segment === "") {
    //   setHomeHeader(true);
    // } else {
    //   setContactHeader(false);
    //   setHomeHeader(false);
    // }
  };

  // const [isClicked, setIsClicked] = useState();

  const handleClick = () => {
    // const dropdown = document.querySelector(".dropdown");
    const dropdown_menu = document.querySelectorAll(".dropdown-menu");
    dropdown_menu.classList.add("hide-navbar");
    // dropdown.classList.remove('no-hover');
    // event.currentTarget.classList.toggle('bg-salmon');
    // setIsClicked(true);
  };

  const [navClick, setNavClick] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);

  return (
    <>
      <Helmet>
        <link
          rel='icon'
          type='image/png'
          href={favicon}
        />
      </Helmet>
      <div className={contactHeader ? "contact-header" : ""}>
        <Router>
          <nav
            className={
              homeHeader
                ? "navbar navbar-expand-xl fixed-top home-header p-0"
                : "navbar navbar-expand-xl fixed-top p-0"
            }
            id='navbarMain'>
            <div className='container'>
              <NavLink to='/'>
                <a className='navbar-brand'>
                  <img
                    src={homeHeader ? brandLogoWhite : brandLogoBlue}
                    alt='BintechServicesLogo'
                    className='brand_logo'
                    onClick={() => [
                      setHomeHeader(true),
                      setNavClick(!navClick),
                    ]}
                  />
                </a>
              </NavLink>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasNavbar'
                aria-controls='offcanvasNavbar'>
                <span className='navbar-toggler-icon'></span>
              </button>
              <div
                className='offcanvas offcanvas-end'
                tabIndex='-1'
                id='offcanvasNavbar'
                aria-labelledby='offcanvasNavbarLabel'>
                <div className='offcanvas-header pb-2 px-4 pt-4'>
                  <span
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'>
                    <NavLink
                      className={"active"}
                      to='/'
                      onClick={() => [
                        setHomeHeader(true),
                        setNavClick(!navClick),
                      ]}>
                      <img
                        src={brandLogoBlue}
                        alt=''
                        className='img-fluid'
                        id='offcanvasNavbarLabel'
                      />
                    </NavLink>
                  </span>
                  <div className='container d-flex justify-content-end align-items-center'>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='offcanvas'
                      aria-label='Close'></button>
                  </div>
                </div>
                <hr className='d-block d-xl-none' />
                <div className='offcanvas-body px-4 py-0'>
                  <ul
                    className='navbar-nav justify-content-end flex-grow-1 pe-3'
                    id='Menu'>
                    <li className='nav-item dropdown'>
                      <div
                        className='nav-link dropdown-toggle'
                        href='#'
                        role='button'
                        data-bs-toggle='dropdown'
                        data-bs-auto-close='outside'
                        aria-expanded='false'>
                        Services
                      </div>
                      {/* mobile */}
                      <ul className='dropdown-menu row d-xl-none justify-content-center g-3 g-xl-5 pb-3 pb-xl-5 pt-0'>
                        <Accordion
                          defaultActiveKey='0'
                          flush
                          className='d-xl-none col-12 col-xl-3 m-0 py-0 mt-3 mt-xl-5'>
                          <Accordion.Item eventKey='0'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_Software}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/technology/software'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Software</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Web Application Development
                                  </li>
                                  <li className='list-navbar-item'>
                                    Online Store Development
                                  </li>
                                  <li className='list-navbar-item'>
                                    Website Design and Development
                                  </li>
                                  <li className='list-navbar-item'>React</li>
                                  <li className='list-navbar-item'>
                                    Custom Software Development
                                  </li>
                                  <li className='list-navbar-item'>
                                    Custom ERP Development
                                  </li>
                                  <li className='link-navbar-item'>ERP</li>
                                  <li className='link-navbar-item'>
                                    Mobile Application Development
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='1'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_Hardware}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/technology/hardware'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Hardware</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Assembled Computer
                                  </li>
                                  <li className='list-navbar-item'>
                                    Gaming PCs
                                  </li>
                                  <li className='list-navbar-item'>
                                    Consumer and Business Laptops
                                  </li>
                                  <li className='list-navbar-item'>
                                    All In Once Computers
                                  </li>
                                  <li className='list-navbar-item'>
                                    Branded Computer
                                  </li>
                                  <li className='list-navbar-item'>
                                    Gaming Laptops
                                  </li>
                                  <li className='list-navbar-item'>
                                    Networking Hardware
                                  </li>
                                  <li className='list-navbar-item'>
                                    Computer Peripherals
                                  </li>
                                  <li className='list-navbar-item'>
                                    Computer and Laptop Repairs
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='2'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_Design}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/design-agency'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Design</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Branding and Logo
                                  </li>
                                  <li className='list-navbar-item'>
                                    Graphics Design
                                  </li>
                                  <li className='list-navbar-item'>
                                    User Interface Design
                                  </li>
                                  <li className='list-navbar-item'>
                                    User Experience Engineering
                                  </li>
                                  <li className='list-navbar-item'>
                                    Video Animations
                                  </li>
                                  <li className='list-navbar-item'>
                                    Print Media Design
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='3'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_BusinessGrowth}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/business-growth'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Business Growth</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Search Engine Optimization
                                  </li>
                                  <li className='list-navbar-item'>
                                    Social Media Marketing
                                  </li>
                                  <li className='list-navbar-item'>
                                    Digital Media Marketing
                                  </li>
                                  <li className='list-navbar-item'>
                                    Marketing Consultancy
                                  </li>
                                  <li className='list-navbar-item'>
                                    Brand Building
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='4'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_NetworkandSecurity}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/network-and-security'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Network And Security</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Network Attached
                                  </li>
                                  <li className='list-navbar-item'>
                                    Storage (NAS)
                                  </li>
                                  <li className='list-navbar-item'>
                                    Infrastrucure Setup
                                  </li>
                                  <li className='list-navbar-item'>
                                    CCTV Camera
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='5'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_Ecommerce}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/e-commerce'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>E-Commerce</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Product Module
                                  </li>
                                  <li className='list-navbar-item'>
                                    Inventory and Stock Module
                                  </li>
                                  <li className='list-navbar-item'>
                                    Sales and Marketing Module
                                  </li>
                                  <li className='list-navbar-item'>
                                    Invoicing and Order Management
                                  </li>
                                  <li className='list-navbar-item'>
                                    Accounting Module
                                  </li>
                                  <li className='list-navbar-item'>
                                    Reseller Module
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='6'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_Travel_Tech}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/travel-tech'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Travel tech</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Hotel Booking Engine
                                  </li>
                                  <li className='list-navbar-item'>
                                    Flight Booking Engine
                                  </li>
                                  <li className='list-navbar-item'>
                                    Transfers Booking Engine
                                  </li>
                                  <li className='list-navbar-item'>
                                    Car Booking and Rental Engine
                                  </li>
                                  <li className='list-navbar-item'>
                                    Activity Booking Engine
                                  </li>
                                  <li className='list-navbar-item'>
                                    Agent Management
                                  </li>
                                  <li className='list-navbar-item'>
                                    Operator Management
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey='7'>
                            <Accordion.Header className='shadow-none border-0'>
                              <span
                                className='navbar-box-upper-container'
                                data-bs-dismiss='offcanvas'
                                aria-label='Close'>
                                <img
                                  src={NavIcon_licencing}
                                  alt=''
                                  className='img-fluid'
                                />
                                <NavLink
                                  to='services/software-licencing'
                                  onClick={() => [
                                    setContactHeader(false),
                                    setHomeHeader(false),
                                    setNavClick(!navClick),
                                  ]}>
                                  <h2>Software Licencing</h2>
                                </NavLink>
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className='py-1'>
                              <div className='navbar-box-content'>
                                <ul className='list-navbar'>
                                  <li className='list-navbar-item'>
                                    Microsoft Windows/Server
                                  </li>
                                  <li className='list-navbar-item'>
                                    Microsoft Office/365
                                  </li>
                                  <li className='list-navbar-item'>
                                    Anti Virus
                                  </li>
                                  <li className='list-navbar-item'>
                                    Adobe Creative Cloud Suite
                                  </li>
                                  <li className='list-navbar-item'>
                                    Corel Graphics Suite
                                  </li>
                                  <li className='list-navbar-item'>
                                    Corel Draw
                                  </li>
                                  <li className='list-navbar-item'>
                                    Tally Accounting Software
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </ul>

                      {/* desktop */}
                      <ul className='dropdown-menu row justify-content-center g-3 g-xl-5 pb-3 pb-xl-5 pt-0'>
                        {/* Software */}
                        <li className='col-12 col-xl-3 m-0 py-0 mt-3 mt-xl-5'>
                          <div className='navbar-box-container d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_Software}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/technology/software'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Software</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Web Application Development
                                </li>
                                <li className='list-navbar-item'>
                                  Online Store Development
                                </li>
                                <li className='list-navbar-item'>
                                  Website Design and Development
                                </li>
                                <li className='list-navbar-item'>React</li>
                                <li className='list-navbar-item'>
                                  Custom Software Development
                                </li>
                                <li className='list-navbar-item'>
                                  Custom ERP Development
                                </li>
                                <li className='link-navbar-item'>ERP</li>
                                <li className='link-navbar-item'>
                                  Mobile Application Development
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* Hardware */}
                        <li className='col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_Hardware}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/technology/hardware'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Hardware</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Assembled Computer
                                </li>
                                <li className='list-navbar-item'>Gaming PCs</li>
                                <li className='list-navbar-item'>
                                  Consumer and Business Laptops
                                </li>
                                <li className='list-navbar-item'>
                                  All In Once Computers
                                </li>
                                <li className='list-navbar-item'>
                                  Branded Computer
                                </li>
                                <li className='list-navbar-item'>
                                  Gaming Laptops
                                </li>
                                <li className='list-navbar-item'>
                                  Networking Hardware
                                </li>
                                <li className='list-navbar-item'>
                                  Computer Peripherals
                                </li>
                                <li className='list-navbar-item'>
                                  Computer and Laptop Repairs
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* Design */}
                        <li className='dropend col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_Design}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/design-agency'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Design</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Branding and Logo
                                </li>
                                <li className='list-navbar-item'>
                                  Graphics Design
                                </li>
                                <li className='list-navbar-item'>
                                  User Interface Design
                                </li>
                                <li className='list-navbar-item'>
                                  User Experience Engineering
                                </li>
                                <li className='list-navbar-item'>
                                  Video Animations
                                </li>
                                <li className='list-navbar-item'>
                                  Print Media Design
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* Business growth */}
                        <li className='col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_BusinessGrowth}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/business-growth'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Business Growth</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Search Engine Optimization
                                </li>
                                <li className='list-navbar-item'>
                                  Social Media Marketing
                                </li>
                                <li className='list-navbar-item'>
                                  Digital Media Marketing
                                </li>
                                <li className='list-navbar-item'>
                                  Marketing Consultancy
                                </li>
                                <li className='list-navbar-item'>
                                  Brand Building
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* Network and Security */}
                        <li className='col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_NetworkandSecurity}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/network-and-security'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Network And Security</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Network Attached
                                </li>
                                <li className='list-navbar-item'>
                                  Storage (NAS)
                                </li>
                                <li className='list-navbar-item'>
                                  Infrastrucure Setup
                                </li>
                                <li className='list-navbar-item'>
                                  CCTV Camera
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* E-commerce */}
                        <li className='col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_Ecommerce}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/e-commerce'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>E-Commerce</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Product Module
                                </li>
                                <li className='list-navbar-item'>
                                  Inventory and Stock Module
                                </li>
                                <li className='list-navbar-item'>
                                  Sales and Marketing Module
                                </li>
                                <li className='list-navbar-item'>
                                  Invoicing and Order Management
                                </li>
                                <li className='list-navbar-item'>
                                  Accounting Module
                                </li>
                                <li className='list-navbar-item'>
                                  Reseller Module
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* Travel tech */}
                        <li className='col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_Travel_Tech}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/travel-tech'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Travel tech</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Hotel Booking Engine
                                </li>
                                <li className='list-navbar-item'>
                                  Flight Booking Engine
                                </li>
                                <li className='list-navbar-item'>
                                  Transfers Booking Engine
                                </li>
                                <li className='list-navbar-item'>
                                  Car Booking and Rental Engine
                                </li>
                                <li className='list-navbar-item'>
                                  Activity Booking Engine
                                </li>
                                <li className='list-navbar-item'>
                                  Agent Management
                                </li>
                                <li className='list-navbar-item'>
                                  Operator Management
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        {/* Software Licencing */}
                        <li className='col-12 col-xl-3'>
                          <div className='navbar-box-container h-100 d-none d-xl-block'>
                            <span
                              className='navbar-box-upper-container'
                              data-bs-dismiss='offcanvas'
                              aria-label='Close'>
                              <img
                                src={NavIcon_licencing}
                                alt=''
                                className='img-fluid'
                              />
                              <NavLink
                                to='services/software-licencing'
                                onClick={() => [
                                  handleClick(),
                                  setContactHeader(false),
                                  setHomeHeader(false),
                                  setNavClick(!navClick),
                                ]}>
                                <h2>Software Licencing</h2>
                              </NavLink>
                            </span>
                            <div className='navbar-box-content'>
                              <ul className='list-navbar'>
                                <li className='list-navbar-item'>
                                  Microsoft Windows/Server
                                </li>
                                <li className='list-navbar-item'>
                                  Microsoft Office/365
                                </li>
                                <li className='list-navbar-item'>Anti Virus</li>
                                <li className='list-navbar-item'>
                                  Adobe Creative Cloud Suite
                                </li>
                                <li className='list-navbar-item'>
                                  Corel Graphics Suite
                                </li>
                                <li className='list-navbar-item'>Corel Draw</li>
                                <li className='list-navbar-item'>
                                  Tally Accounting Software
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>

                    <li
                      className='nav-item'
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setNavClick(!navClick),
                      ]}>
                      <span
                        data-bs-dismiss='offcanvas'
                        aria-label='Close'>
                        <NavLink
                          to='about-us'
                          className='nav-link'>
                          About Us
                        </NavLink>
                      </span>
                    </li>

                    {/* <li
                      className="nav-item"
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setNavClick(!navClick),
                      ]}
                    >
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="blog" className="nav-link">
                          Blog
                        </NavLink>
                      </span>
                    </li> */}
                    {/* 
                    <li
                      className="nav-item"
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setNavClick(!navClick),
                      ]}
                    >
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="blog-single" className="nav-link">
                          Blog-single
                        </NavLink>
                      </span>
                    </li> */}

                    <li
                      className='nav-item'
                      onClick={() => [
                        setContactHeader(false),
                        setHomeHeader(false),
                        setNavClick(!navClick),
                      ]}>
                      <span
                        data-bs-dismiss='offcanvas'
                        aria-label='Close'>
                        <NavLink
                          to='contact'
                          className='nav-link'>
                          Contact Us
                        </NavLink>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          <Routes>
            <Route
              path='/'
              element={<Home />}
            />
            <Route
              path='services/design-agency'
              element={<Design />}
            />
            <Route
              path='services/technology'
              element={<Technology />}
            />
            <Route
              path='services/technology/software'
              element={<Technology_software />}
            />
            <Route
              path='services/technology/hardware'
              element={<Technology_hardware />}
            />
            <Route
              path='services/business-growth'
              element={<Business />}
            />
            <Route
              path='services/network-and-security'
              element={<NetworkSecurity />}
            />
            <Route
              path='services/software-licencing'
              element={<Software_licencing />}
            />
            <Route
              path='services/e-commerce'
              element={<Ecommerce />}
            />
            <Route
              path='contact'
              element={<Contact />}
            />
            <Route
              path='about-us'
              element={<About />}
            />
            {/* <Route path="blog" element={<Blog />} />
            <Route path="blog-single" element={<Blog_single />} /> */}
          </Routes>
        </Router>
      </div>
    </>
  );
};

export default Header;
