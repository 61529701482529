import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import astrik from "../../assets/svg/astrik.svg";
import Footer from "../../components/Footer_services";
import "../../css/policy.css";
import Loader_page from "../../components/Loader_page";

function Website_Usage_Policy() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 10);
    };

    loadData();
  }, []);
  if (loading) {
    return <Loader_page />;
  } else {
    return (
      <>
        <Helmet>
          <title>Bintech Services Digital Agency</title>
          <meta name="title" content="Unleash the power of your data" />
          <meta
            name="description"
            content="Bintech is a full-service digital agency that offers a range of solutions to help businesses establish a strong online presence."
          />
          <meta
            name="keywords"
            content="best digital agency ahmedabad, best digital agency Gujarat, Digital Agency, cctv camera installation in ahmedabad, best digital agency india"
          />
        </Helmet>

        {/* contact */}
        <div className="policy">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12">
                <span className="fw-bold display-5 text-blue position-relative">
                  <img src={astrik} alt="" className="astrik" />
                  Website Usage Policy
                </span>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>BinTech Services Website Usage Policy :</strong>
                </p>
                <p className="fs-6">
                  This Website Usage Policy ("Policy") outlines the terms and
                  conditions governing your use of the BinTech Services website
                  located at{" "}
                  <a href="https://bintech.services/" target="_blank">
                    https://bintech.services
                  </a>{" "}
                  (the "Website").
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>Project Revisions :</strong>
                </p>
                <p className="fs-6">
                  Minor revisions within the originally agreed-upon scope of
                  work will be accommodated at no additional cost.
                </p>
                <p className="fs-6">
                  Any significant changes requiring additional work outside the
                  initial scope may incur extra charges based on the time and
                  resources involved.
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>Refund Policy :</strong>
                </p>
                <p className="fs-6">
                  Due to the nature of service-based offerings, full refunds
                  will not be granted after project commencement.
                </p>
                <p className="fs-6">
                  In case of unforeseen circumstances where BinTech Services is
                  unable to deliver the agreed-upon services, a prorated refund
                  will be issued based on the undelivered portion of the
                  project.
                </p>
                <p className="fs-6">
                  If a client is dissatisfied with the service quality during
                  the initial stages of the project (within a specified
                  timeframe, e.g., 7 days), BinTech Services will work
                  diligently to address the concerns and rectify the situation.
                </p>
                <p className="fs-6">
                  Should the issue remain unresolved after a good faith effort,
                  a partial refund may be considered on a case-by-case basis.
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>Client Communication :</strong>
                </p>
                <p className="fs-6">
                  BinTech Services maintains open communication channels
                  throughout the project lifecycle. Clients are encouraged to
                  provide timely feedback and raise concerns promptly to allow
                  for corrective measures.
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>Dispute Resolution :</strong>
                </p>
                <p className="fs-6">
                  BinTech Services is committed to resolving any disputes
                  amicably.
                </p>
                <p className="fs-6">
                  Clients with concerns should first attempt to reach a solution
                  directly with the designated project manager.
                </p>
                <p className="fs-6">
                  If the issue persists, BinTech Services is open to exploring
                  alternative solutions like mediation or arbitration.
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>Please Note :</strong>
                </p>
                <p className="fs-6">
                  This is a sample Refund and Return Policy and may need to be
                  adapted to reflect BinTech Services' specific service
                  offerings and client engagement processes. Consulting with a
                  legal professional is recommended to ensure the Policy adheres
                  to all applicable laws and regulations.
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <p className="mb-3 fs-5">
                  <strong>Disclaimer :</strong>
                </p>
                <p className="fs-6">
                  This policy solely addresses BinTech Services' approach to
                  handling service delivery and potential client
                  dissatisfaction. It does not constitute any endorsement or
                  affiliation with Razorpay. Payment gateway specific terms and
                  conditions regarding refunds might exist and should be
                  reviewed separately.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Website_Usage_Policy;
